import logo from './logo.svg';
import './App.css';
import { Component } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import ReactDOM from 'react-dom';

class App extends Component {
  constructor() {
    super();
    this.state = {
      name: '',
      content: '',
      topic: ''
    };

    this.create = this.create.bind(this);
  }

  componentDidMount() {
    this.getMyNotes()
  }

  async getMyNotes () {
    var notesList = [];
    try {
        notesList.push(
          <div key='heading'>
            <Row style={{borderBottom: '1px solid'}} >
              <Col>
                <p>Name</p>
              </Col>
              <Col>
                <p>Topic</p>
              </Col>
              <Col>
                <p>Content</p>
              </Col>
            </Row>
          </div>
        )
        const response = await fetch('https://ibnotes.kesar.dev/api/latestnotes')
        const json = await response.json();
        for (let i = 0; i < json.length; i++) {
          const note = json[i];
          notesList.push(
            <div key={i}>
            <Row style={{paddingTop: '10px'}} >
              <Col>
                <p>{note.name}</p>
              </Col>
              <Col>
                <p>{note.topic}</p>
              </Col>
              <Col>
                <p>{note.description}</p>
              </Col>
            </Row>
          </div>
          )
        }
    } catch (err) {
        console.log(err);
    }

    ReactDOM.render(notesList, document.getElementById('notes'))
  }

  async create() {
    const create = await fetch('https://ibnotes.kesar.dev/api/newnote', {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json'
      },
      body: JSON.stringify({
          type: 'notes',
          name: this.state.name,
          topic: this.state.topic,
          description: this.state.content
      })
    }).then((res) => res.json());
    console.log(create)
    window.open("https://notes.kesar.dev/","_self")
  }

  render() {
    return(
      <div className='App'>
        <Container>
          <Card className='justify-content-center my-5' style={{padding: '30px', borderRadius: '2rem', textAlign: 'left', color: 'white', background: 'none'}}>
            <Card.Body>
              <h1 style={{padding: '30px', borderRadius: '2rem', border: '2px solid'}}>Glossary</h1>
              <br/>
              <div style={{padding: '30px', borderRadius: '2rem', border: '2px solid'}}>
                 <Row className='align-items-center ' style={{padding: '2%'}}>
                    <Col className='mb-3' xs={{span: 12}}>
                      <input placeholder="Name" className="form-control" onChange={ (e) => {this.setState({name: e.target.value})}} required/>
                    </Col>
                    <Col className='mb-3' xs={{span: 12}}>
                      <input placeholder="Topic" className="form-control" onChange={ (e) => {this.setState({topic: e.target.value})}} required/>
                    </Col>
                    <Col className='mb-3' xs={{span: 12}}>
                      <textarea type="text" maxLength="1000000" rows="10"  className="form-control" placeholder="Content" onChange={ (e) => {this.setState({content: e.target.value})}} required/>
                    </Col>
                    <Col className='mb-3' xs={{span: 12}}>
                      <Button className="border-0 btn btn-primary d-block btn-user w-100" type="submit" onClick={this.create}>Create New Note</Button>
                    </Col>
                  </Row>
              </div>
              <br/>
              <div style={{padding: '30px', borderRadius: '1.5rem', border: '2px solid'}} className='notes' id='notes'></div>
            </Card.Body>
          </Card>
        </Container>
      </div>
    )
  }
}

export default App;
